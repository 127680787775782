import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  Alarm,
  AppLogin,
  Calendar,
  CompactTest,
  Credit,
  CreditBuy,
  CustomizedTest,
  DiagnosisTest,
  Event,
  EventDetail,
  FAQ,
  FindPassword,
  FullTest,
  Info,
  Join,
  LessonVideo,
  Login,
  Main,
  MyCoupon,
  MyCredit,
  MyPoint,
  Online,
  PostQna,
  EditQna,
  PrevTest,
  Question,
  Root,
  SectionTest,
  SNSJoin,
  Suggestion,
  SuggestResult,
  Terms,
  DiagnosisTestDetail,
  WrongTest,
  MyNote,
  AppLoginKakao,
  AppLoginGoogle,
  AppLoginLine,
  FullTestDetail,
  Share,
  CompactTestDetail,
  SectionTestDetail,
  CustomizedTestDetail,
  WrongTestDetail,
  Retest,
  AddInfo,
  AppLoginApple,
  AppLoginWechat,
  Complete,
  StudyData,
  PaymentResult,
} from "../pages";
import { api } from "../utils/api";
import { SWRConfig } from "swr";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t, i18n } = useTranslation();

  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language"));
  }, []);

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Root} />

          <Route exact path="/app/login" component={AppLogin} />
          <Route exact path="/app/login/kakao" component={AppLoginKakao} />
          <Route exact path="/app/login/google" component={AppLoginGoogle} />
          <Route exact path="/app/login/line" component={AppLoginLine} />
          <Route exact path="/app/login/apple" component={AppLoginApple} />
          <Route exact path="/app/login/wechat" component={AppLoginWechat} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/sns/join" component={SNSJoin} />
          <Route exact path="/addinfo" component={AddInfo} />
          <Route exact path="/find" component={FindPassword} />

          {/* 결제완료 후 */}
          <Route exact path="/payment/result" component={PaymentResult} />

          <Route exact path="/main" component={Main} />
          <Route exact path="/diagnosis" component={DiagnosisTest} />
          <Route exact path="/diagnosisTest" component={DiagnosisTestDetail} />
          <Route exact path="/full" component={FullTest} />
          <Route exact path="/full/:level" component={FullTestDetail} />
          <Route exact path="/compact" component={CompactTest} />
          <Route exact path="/compact/:level" component={CompactTestDetail} />
          <Route exact path="/section" component={SectionTest} />
          <Route exact path="/section/:level" component={SectionTestDetail} />
          <Route exact path="/custom" component={CustomizedTest} />
          <Route exact path="/custom/:level" component={CustomizedTestDetail} />
          <Route exact path="/wrong" component={WrongTest} />
          <Route exact path="/wrong/:level" component={WrongTestDetail} />
          <Route exact path="/share/:id/:type/:create" component={Share} />

          <Route exact path="/online" component={Online} />
          <Route exact path="/study" component={StudyData} />

          <Route exact path="/suggestion" component={Suggestion} />
          <Route exact path="/suggest/result" component={SuggestResult} />

          <Route exact path="/alarm" component={Alarm} />

          <Route exact path="/credit" component={Credit} />
          <Route exact path="/credit/buy" component={CreditBuy} />
          <Route exact path="/credit/payment/complete" component={Complete} />

          <Route exact path="/mypage" component={Info} />
          <Route exact path="/mypage/calendar" component={Calendar} />
          <Route exact path="/mypage/note" component={MyNote} />
          <Route exact path="/mypage/test" component={Retest} />
          <Route exact path="/mypage/prev" component={PrevTest} />
          <Route exact path="/mypage/video" component={LessonVideo} />
          <Route exact path="/mypage/coupon" component={MyCoupon} />
          <Route exact path="/mypage/point" component={MyPoint} />
          <Route exact path="/mypage/credit" component={MyCredit} />
          <Route exact path="/mypage/event/:page" component={Event} />
          <Route
            exact
            path="/mypage/event/detail/:id"
            component={EventDetail}
          />
          <Route exact path="/mypage/question" component={Question} />
          <Route exact path="/mypage/question/post" component={PostQna} />
          <Route exact path="/mypage/question/eidt/:id" component={EditQna} />

          <Route exact path="/mypage/faq" component={FAQ} />

          <Route exact path="/terms/:type" component={Terms} />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
