import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import langEn from "./lang.en.json";
import langKo from "./lang.ko.json";
import langTw from "./lang.tw.json";
import langCn from "./lang.cn.json";

const resources = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
  tw: {
    translation: langTw,
  },
  cn: {
    translation: langCn,
  },
};

i18n.use(initReactI18next).init({
  resources: resources,
  // 초기 설정 언어
  lng: "ko",
  fallbackLng: "ko",
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
