const { parse } = JSON;
const auth = {
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },
  clearAppStorage() {
    return localStorage.clear();
  },
  get(key) {
    return parse(localStorage.getItem(key));
  },
  setToken(token) {
    return localStorage.setItem("token", token);
  },
  getToken() {
    return localStorage.getItem("token");
  },
  setRefreshToken(token) {
    return localStorage.setItem("refreshToken", token);
  },
  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  },
  setEmail(email) {
    return localStorage.setItem("email", email);
  },
  getEmail() {
    return localStorage.getItem("email");
  },
  setCredit(credit) {
    return localStorage.setItem("credit", credit);
  },
  getCredit() {
    return localStorage.getItem("credit");
  },
  setTest(test) {
    return localStorage.setItem("test", test);
  },
  getTest() {
    return localStorage.getItem("test");
  },
  setNote(note) {
    return localStorage.setItem("note", note);
  },
  getNote() {
    return localStorage.getItem("note");
  },
  setSchedule(schedule) {
    return localStorage.setItem("schedule", schedule);
  },
  getSchedule() {
    return localStorage.getItem("schedule");
  },
};
export default auth;
