import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));

export const AppLogin = loadable(() => import("./user/AppLogin"));
export const AppLoginKakao = loadable(() => import("./user/AppLoginKakao"));
export const AppLoginGoogle = loadable(() => import("./user/AppLoginGoogle"));
export const AppLoginLine = loadable(() => import("./user/AppLoginLine"));
export const AppLoginApple = loadable(() => import("./user/AppLoginApple"));
export const AppLoginWechat = loadable(() => import("./user/AppLoginWechat"));
export const Login = loadable(() => import("./user/Login"));
export const Join = loadable(() => import("./user/Join"));
export const SNSJoin = loadable(() => import("./user/SNSJoin"));
export const AddInfo = loadable(() => import("./user/AddInfo"));
export const FindPassword = loadable(() => import("./user/FindPassword"));

export const PaymentResult = loadable(() => import("./user/PaymentResult"));

export const Main = loadable(() => import("./Main"));
export const DiagnosisTest = loadable(() => import("./DiagnosisTest"));
export const DiagnosisTestDetail = loadable(() =>
  import("./DiagnosisTestDetail")
);
export const FullTest = loadable(() => import("./FullTest"));
export const FullTestDetail = loadable(() => import("./FullTestDetail"));
export const CompactTest = loadable(() => import("./CompactTest"));
export const CompactTestDetail = loadable(() => import("./CompactTestDetail"));
export const SectionTest = loadable(() => import("./SectionTest"));
export const SectionTestDetail = loadable(() => import("./SectionTestDetail"));
export const CustomizedTest = loadable(() => import("./CustomizedTest"));
export const CustomizedTestDetail = loadable(() =>
  import("./CustomizedTestDetail")
);
export const WrongTest = loadable(() => import("./WrongTest"));
export const WrongTestDetail = loadable(() => import("./WrongTestDetail"));
export const Share = loadable(() => import("./Share"));

export const Online = loadable(() => import("./Online"));
export const StudyData = loadable(() => import("./StudyData"));

export const Suggestion = loadable(() => import("./Suggestion"));
export const SuggestResult = loadable(() => import("./SuggestResult"));

export const Alarm = loadable(() => import("./Alarm"));

export const Credit = loadable(() => import("./credit/Credit"));
export const CreditBuy = loadable(() => import("./credit/CreditBuy"));
export const Complete = loadable(() => import("./credit/Complete"));

export const Info = loadable(() => import("./mypage/Info"));
export const Calendar = loadable(() => import("./mypage/Calendar"));
export const MyNote = loadable(() => import("./mypage/MyNote"));
export const Retest = loadable(() => import("./mypage/Retest"));
export const PrevTest = loadable(() => import("./mypage/PrevTest"));
export const LessonVideo = loadable(() => import("./mypage/LessonVideo"));
export const MyCoupon = loadable(() => import("./mypage/Coupon"));
export const MyPoint = loadable(() => import("./mypage/Point"));
export const MyCredit = loadable(() => import("./mypage/Credit"));

export const Event = loadable(() => import("./mypage/Event"));
export const EventDetail = loadable(() => import("./mypage/EventDetail"));
export const Question = loadable(() => import("./mypage/Question"));
export const PostQna = loadable(() => import("./mypage/PostQna"));
export const EditQna = loadable(() => import("./mypage/EditQna"));
export const FAQ = loadable(() => import("./mypage/FAQ"));

export const Terms = loadable(() => import("./Terms"));
