import Axios from "axios";
import auth from "./auth";
export const endpoint = "https://api.onlinejlpt.com/api";
// export const endpoint = "https://api.onlinejlpt.cn/api";

export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      if (
        window.location.href.includes("join") ||
        window.location.href.includes("diagnosis")
      ) {
        auth.clear("token");
      } else {
        //window.alert("로그인이 필요한 페이지입니다.");
        auth.clear("token");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);
